import React, { useEffect, useState } from 'react'
import './FourBlock.css'
import arrow from './media/Polygon 2.png'
import arrow1 from './media/Polygon 2 (1).png'

import price1en from './media/price1en.png'
import price2en from './media/price2en.png'
import price31en from './media/price31en.png'
import price32en from './media/price32en.png'
import price4en from './media/price4en.png'
import price1zn from './media/price1zn.png'
import price2zn from './media/price2zn.png'
import price31zn from './media/price31zn.png'
import price32zn from './media/price32zn.png'
import price4zn from './media/price4zn.png'
import { Navigation, Pagination } from 'swiper/modules'

import card1 from './media/image 23.png'
import card2 from './media/image 18.png'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
function FourBlock(props) {
	const [leftbtn, setleftbtn] = useState('')
	const [rightbtn, setrightbtn] = useState('')
	const [cards1, setcards1] = useState('FourBlock-cards__bottom')
	const [cards2, setcards2] = useState('FourBlock-cards__bottom')
	const [arrowbtn1, setarrowbtn1] = useState(arrow)
	const [arrowbtn2, setarrowbtn2] = useState(arrow)
	const [price1, setprice1] = useState(price1zn)
	const [price2, setprice2] = useState(price2zn)
	const [price31, setprice31] = useState(price31zn)
	const [price32, setprice32] = useState(price32zn)
	const [price4, setprice4] = useState(price4zn)

	const { t, i18n } = useTranslation()

	const activelanguage = () => {
		if (t('about us') === 'Our cases') {
			setprice1(price1en)
			setprice2(price2en)
			setprice31(price31en)
			setprice32(price32en)
			setprice4(price4en)
		} else {
			setprice1(price1zn)
			setprice2(price2zn)
			setprice31(price31zn)
			setprice32(price32zn)
			setprice4(price4zn)
		}
	}
	function AdaptiveSize() {
		if (window.innerWidth <= 1366 && window.innerWidth > 1024) {
			return 3
		} else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
			return 2.5
		} else if (window.innerWidth <= 768 && window.innerWidth > 640) {
			return 1.5
		} else if (window.innerWidth <= 640 && window.innerWidth > 480) {
			return 1.2
		} else if (window.innerWidth <= 479) {
			return 1.1
		} else {
			return 3
		}
	}
	const reset = () => {
		setleftbtn('')
		setrightbtn('')
		setcards1('FourBlock-cards__bottom')
		setcards2('FourBlock-cards__bottom')
		setarrowbtn1(arrow)
		setarrowbtn2(arrow)
	}
	const leftbtnfuncktion = () => {
		if (arrowbtn1 === arrow) {
			reset()
			setleftbtn('select-btn')
			setcards1('FourBlock-cards__bottom select-cards')
			setarrowbtn1(arrow1)
		} else {
			reset()
		}
	}
	const rightbtnfuncktion = () => {
		if (arrowbtn2 === arrow) {
			reset()
			setrightbtn('select-btn')
			setcards2('FourBlock-cards__bottom select-cards')
			setarrowbtn2(arrow1)
		} else {
			reset()
		}
	}
	useEffect(() => {
		activelanguage()
	})
	return (
		<div className='FourBlock'>
			<div className='FourBlock_container' id='three'>
				<h4>{t('fourblockh4')}</h4>
				<div className='FourBlock-cards'>
					<div className='FourBlock_card-left'>
						<div className='cardtext'>
							<h5>{t('fourblockh51')}</h5>
							<p>{t('fourblockp1')}</p>
						</div>
						<div className='cardbottom'>
							<img src={card1} alt='card1' />
							<button onClick={() => leftbtnfuncktion()} className={leftbtn}>
								{t('fourblockbtn1')} <img src={arrowbtn1} alt='arrow' />
							</button>
						</div>
					</div>
					<div className='FourBlock_card-right'>
						<div className='cardtext'>
							<h5>{t('fourblockh52')}</h5>
							<p>{t('fourblockp2')}</p>
						</div>
						<div className='cardbottom'>
							<img src={card2} alt='card2' />
							<button onClick={() => rightbtnfuncktion()} className={rightbtn}>
								{t('fourblockbtn2')} <img src={arrowbtn2} alt='arrow' />
							</button>
							<span>{t('fourblockspan')}</span>
						</div>
					</div>
				</div>
				<div className={cards1}>
					<Swiper
						modules={[Navigation]}
						spaceBetween={10}
						navigation={{ clickable: true }}
						slidesPerView={AdaptiveSize()}
						onSlideChange={() => console.log('slide change')}
						onSwiper={swiper => console.log(swiper)}
					>
						<SwiperSlide>
							<div className='card-Organizational'>
								<div className='top'>
									<h6>{t('card1h6')}</h6>
									<div className='text-box'>
										<span>{t('card1span1')}</span>
										<b>249 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card1span2')}</span>
										<b>1199 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card1span3')}</span>
										<b>159 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card1span4')}</span>
										<b>159 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card1span5')}</span>
										<b>99 ¥</b>
									</div>
								</div>
								<div className='bottom'>
									<img className='price' src={price1} alt='price1' />
									<button onClick={() => (window.location.href = '#four')}>
										WeChat
									</button>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='card-QuickStart'>
								<div className='top'>
									<h6>{t('card2h6')}</h6>
									<div className='text-box'>
										<span>{t('card2span1')}</span>
										<b>69 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span2')}</span>
										<b>449 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span3')}</span>
										<b>69 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span4')}</span>
										<b>549 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span5')}</span>
										<b>1199 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span6')}</span>
										<b>89 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span7')}</span>
										<b>{t('card2b1')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span8')}</span>
										<b>{t('card2b2')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card2span9')}</span>
										<b>449 ¥</b>
									</div>
								</div>
								<div className='bottom'>
									<img className='price' src={price2} alt='price2' />
									<button onClick={() => (window.location.href = '#four')}>
										WeChat
									</button>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='card-PromotionAndMaintenance'>
								<div className='top'>
									<h6>{t('card3h6')}</h6>
									<div className='text-box'>
										<span>{t('card3span1')}</span>
										<b>49 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span2')}</span>
										<b>49 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span3')}</span>
										<b>509 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span4')}</span>
										<b>49 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span5')}</span>
										<b>29 ¥</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span6')}t</span>
										<b>89 ¥</b>
									</div>
									<img className='price' src={price31} alt='price3' />
									<div className='text-box'>
										<span>{t('card3span7')}</span>
										<b>{t('card3b1')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span9')}</span>
										<b>{t('card3b3')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span10')}</span>
										<b>{t('card3b4')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card3span8')}</span>
										<b>{t('card3b2')}</b>
									</div>
								</div>
								<div className='bottom'>
									<img className='price' src={price32} alt='price3' />
									<button onClick={() => (window.location.href = '#four')}>
										WeChat
									</button>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='card-PromotionAndMaintenance'>
								<div className='top'>
									<h6>{t('card4h6')}</h6>
									<div className='text-box'>
										<span>{t('card4span1')}</span>
										<b>{t('card4b1')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card4span2')}</span>
										<b>{t('card4b2')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card4span3')}</span>
										<b>{t('card4b3')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card4span4')}</span>
										<b>{t('card4b4')}</b>
									</div>
									<div className='text-box'>
										<span>{t('card4span5')}</span>
										<b>{t('card4b5')}</b>
									</div>
								</div>
								<div className='bottom'>
									<img className='price1' src={price4} alt='price4' />
									<button onClick={() => (window.location.href = '#four')}>
										WeChat
									</button>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
				<div className={cards2}>
					<Swiper
						spaceBetween={5}
						slidesPerView={AdaptiveSize()}
						onSlideChange={() => console.log('slide change')}
						onSwiper={swiper => console.log(swiper)}
					>
						<SwiperSlide>
							<div className='card-OrganizationOfProduction'>
								<div className='top'>
									<h6>{t('card5h6')}</h6>
									<ol>
										<li>{t('card5li1')}</li>
										<li>{t('card5li2')}</li>
										<li>{t('card5li3')}</li>
										<li>{t('card5li4')}</li>
									</ol>
								</div>
								<button onClick={() => (window.location.href = '#four')}>
									WeChat
								</button>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='card-AccessToWholesalers'>
								<div className='top'>
									<h6>{t('card6h6')}</h6>
									<ol>
										<li>{t('card6li1')}</li>
										<li>{t('card6li2')}</li>
										<li>{t('card6li3')}</li>
										<li>{t('card6li4')}</li>
									</ol>
								</div>
								<button onClick={() => (window.location.href = '#four')}>
									WeChat
								</button>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='card-Investment'>
								<div className='top'>
									<h6>{t('card7h6')}</h6>
									<p>{t('card7p1')}</p>
								</div>
								<button onClick={() => (window.location.href = '#four')}>
									WeChat
								</button>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</div>
	)
}

export default FourBlock
