import React, { useEffect, useState } from 'react'
import './ThirdBlock.css'
import wb from './media/WILDBERRIES.png'
import ozon from './media/OZON.png'
import { useTranslation } from 'react-i18next'

function ThirdBlock(props) {
	const { t, i18n } = useTranslation()
	return (
		<div className='ThirdBlock' id='two'>
			<div className='ThirdBlock_container'>
				<h3>{t('thrdblockh3')}</h3>
				<div className='ThirdBlock-cards'>
					<div className='ThirdBlock__wb'>
						<img src={wb} alt='wb' />
						<b>548 000</b>
						<span>{t('thrdblockspan1')}</span>
						<b>{t('thrdblockb21')}</b>
						<span>{t('thrdblockspan2')}</span>
						<b>{t('thrdblockb1')}</b>
						<span>{t('thrdblockspan3')}</span>
						{/* <button>More detailed</button> */}
					</div>
					<div className='ThirdBlock__ozon'>
						<img src={ozon} alt='ozon' />
						<b>200 000</b>
						<span>{t('thrdblockspan4')}</span>
						<b>{t('thrdblockb22')}</b>
						<span>{t('thrdblockspan5')}</span>
						<b>{t('thrdblockb2')}</b>
						<span>{t('thrdblockspan6')}</span>
						{/* <button>More detailed</button> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ThirdBlock
