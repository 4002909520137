import React, { useEffect, useRef, useState } from 'react'
import './FiveBlock.css'
import emailjs from '@emailjs/browser'
import qr from './media/Снимок экрана 2024—03—12 в 02.12 2.png'
import { useTranslation } from 'react-i18next'
function FiveBlock(props) {
	const { t, i18n } = useTranslation()
	const [leftbtn, setleftbtn] = useState('none')
	const [act, setact] = useState(true)
	const form = useRef()
	const [checked, setChecked] = useState(false)
	const [checkedinput, setCheckedinput] = useState(null)

	function chengeCheckbox() {
		setChecked(!checked)
	}
	const sendEmail = e => {
		e.preventDefault()

		emailjs
			.sendForm('service_bnkbmmq', 'template_9s8y3pb', form.current, {
				publicKey: '0QlwlfPoe0tzMnYWS',
			})
			.then(
				() => {
					console.log('SUCCESS!')
				},
				error => {
					console.log('FAILED...', error.text)
				}
			)
	}
	const click = () => {
		if ((checked === true) & (checkedinput != null)) {
			if (act === true) {
				setleftbtn('show')
				setact(false)
			} else {
				setleftbtn('none')
				setact(true)
			}
		}
	}
	return (
		<div className='FiveBlock' id='four'>
			<div className='FiveBlock_container'>
				<form ref={form} onSubmit={sendEmail}>
					<h6>{t('fiveblockh6')}</h6>
					<p>{t('fiveblockp1')}</p>
					<input
						name='wechat_name'
						type='text'
						onChange={e => setCheckedinput(e.target.value)}
						placeholder='WeChat'
					/>
					<div className='FiveBlock-checkbox'>
						<input
							type='checkbox'
							checked={checked}
							onChange={chengeCheckbox}
							name='happy'
							id='happy'
						/>
						<label for='happy'></label>
						<span>{t('fiveblockspan')}</span>
					</div>
					<img className={leftbtn} src={qr} alt='qr' />
					<button type='submit' onClick={() => click()}>
						WeChat
					</button>
				</form>
			</div>
		</div>
	)
}

export default FiveBlock
