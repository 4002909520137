import React, { useEffect, useState } from 'react'
import video1 from './media/video.mp4'
import qr from './media/qr.png'
import arrow from './media/Frame 145.png'
import './SecondBlock.css'
import { useTranslation } from 'react-i18next'

function SecondBlock(props) {
	const { t, i18n } = useTranslation()
	return (
		<div className='SecondBlock' id='one'>
			<div className='SecondBlock_container'>
				<h2>{t('scndblockh2')}</h2>
				<div className='SecondBlock-qr'>
					<div className='SecondBlock-qr__left'>
						<b>{t('scndblockb1')}</b>
						<div className='SecondBlock-qr__left-arrow'>
							<b>{t('scndblockb2')}</b>
							<img src={arrow} alt='arrow' />
						</div>
					</div>
					<img src={qr} alt='qr' />
				</div>
				<video controls='true'>
					<source src={video1} type='video/mp4' />
				</video>
			</div>
		</div>
	)
}

export default SecondBlock
