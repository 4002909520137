import React, { useEffect, useState } from 'react'
import './Footer.css'
import logo from './media/logo.png'
import { useTranslation } from 'react-i18next'
function Footer(props) {
	const { t, i18n } = useTranslation()
	return (
		<div className='Footer' id='five'>
			<div className='Footer_container'>
				<div className='Footer-left'>
					<img src={logo} alt='logo' />
					<span>© 2024 «AMUR PARTNERS» </span>
				</div>
				<div className='Footer-center'>
					<img src={logo} alt='logo' />
					<b>{t('footerb1')}</b>
					<ul>
						<a href='#one'>{t('about us')}</a>
						<a href='#three'>{t('cases')}</a>
						<a href='#four'>{t('prices')}</a>
						<a href='#five'>{t('feedback')}</a>
					</ul>
					<span>© 2024 «AMUR PARTNERS» </span>
				</div>
				<div className='Footer-right'>
					<div className='Footer_text'>
						<b>{t('footerb2')}</b>
						<span>amurpartners@gmail.com</span>
						<span>Moscow, Leninsky Prospekt 105/2</span>
						<span>9:00 - 21:00 (UTC +3)</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
