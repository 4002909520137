import React, { useEffect, useState } from 'react'
import './Main.css'
import Amur from './media/Frame 2.png'
import { useTranslation } from 'react-i18next'
function Main(props) {
	const { t, i18n } = useTranslation()

	return (
		<div className='Main'>
			<div className='Main_container'>
				<img src={Amur} alt='Amur' />
				<p>{t('maintxt')}</p>
				<div className='Main-buttons'>
					<button onClick={() => (window.location.href = '#three')}>
						<p>{t('mainbtn1')}</p>{' '}
					</button>
					<button onClick={() => (window.location.href = '#three')}>
						<p>{t('mainbtn2')}</p>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Main
