import React, { useEffect, useState } from 'react'
import logo from './media/logo.png'
import './Header.css'
import { useTranslation } from 'react-i18next'

function Header(props) {
	const [en, seten] = useState('LanguageButton-text')
	const [zn, setzn] = useState('LanguageButton-text')
	const { t, i18n } = useTranslation()
	const activelanguage = () => {
		if (t('about us') === 'Our cases') {
			setzn('LanguageButton-text')
			seten('LanguageButton-text choose')
		} else {
			setzn('LanguageButton-text choose')
			seten('LanguageButton-text')
		}
	}
	const changeLanguage = () => {
		if (t('about us') === 'Our cases') {
			i18n.changeLanguage('zn')
		} else {
			i18n.changeLanguage('en')
		}
	}
	useEffect(() => {
		activelanguage()
	})
	return (
		<header>
			<div className='Header_container'>
				<div className='Header-navigation'>
					<nav>
						<img src={logo} alt='logo' />
						<ul>
							<a href='#one'>{t('about us')}</a>
							<a href='#three'>{t('cases')}</a>
							<a href='#four'>{t('prices')}</a>
							<a href='#five'>{t('feedback')}</a>
						</ul>
					</nav>
					<div className='Header-navigation__right'>
						<span>{t('language')}</span>
						<div onClick={() => changeLanguage()} className='LanguageButton'>
							<div onClick={() => changeLanguage()} className={zn}>
								<span onClick={() => changeLanguage()}>中文</span>
							</div>
							<div onClick={() => changeLanguage()} className={en}>
								<span onClick={() => changeLanguage()}>EN</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
