import './App.css'
import FiveBlock from './components/FiveBlock/FiveBlock'
import Footer from './components/Footer/Footer'
import FourBlock from './components/FourBlock/FourBlock'
import Header from './components/Header/Header'
import Main from './components/Main/Main'
import SecondBlock from './components/SecondBlock/SecondBlock'
import SixBlock from './components/SixBlock/SixBlock'
import ThirdBlock from './components/ThirdBlock/ThirdBlock'

function App() {
	return (
		<>
			<Header />
			<Main />
			<SecondBlock />
			<ThirdBlock />
			<FourBlock />
			<FiveBlock />
			<Footer />
		</>
	)
}

export default App
